import { SettingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Flex, Image, Layout, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserRoleType } from '../../constants';
import useRoles from '../../hooks/useRoles';
import { White } from '../../styles';

const { Header } = Layout;
const { Text } = Typography;

const StyledHeader = styled(Header)`
  padding: 0 24px;
  height: 100%;
  background: ${White};
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledImage = styled(Image)`
  cursor: pointer;
`;

function NavBar({ signupComplete }) {
  const navigate = useNavigate();
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { roles } = useRoles();
  const imgixUrl = process.env.REACT_APP_IMGIX_URL;

  return (
    <StyledHeader>
      <Flex align="center" justify="space-between" gap={24}>
        <StyledImage
          alt="logo"
          preview={false}
          width={175}
          src={`${imgixUrl}/static/fathom-logo.svg`}
          onClick={() => navigate('/')}
        />
        <Flex align="center" gap={8}>
          {signupComplete && (
            <>
              {isAuthenticated && <Text>Welcome, {user.name}</Text>}
              {roles.includes(UserRoleType.ADMINISTRATOR) && (
                <Button
                  icon={<SettingOutlined />}
                  type="text"
                  size="large"
                  onClick={() => navigate('/settings')}
                />
              )}
              {isAuthenticated ? (
                <Button
                  data-cy="logout-button"
                  onClick={() => {
                    window.Intercom('shutdown');
                    logout({ logoutParams: { returnTo: window.location.origin } });
                  }}
                >
                  Log out
                </Button>
              ) : (
                <Button type="primary" onClick={() => loginWithRedirect()}>
                  Log in
                </Button>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </StyledHeader>
  );
}

NavBar.propTypes = {
  signupComplete: PropTypes.bool.isRequired,
};

export default NavBar;
