import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Card, Col, Flex, Row, Select, Space, Switch, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactFC from 'react-fusioncharts';
import styled from 'styled-components';
import {
  useAggregateResponses,
  useCodeFrames,
  useUpdateDisplaySentiment,
} from '../../../api/Questions';
import { useSurveySummary } from '../../../api/Surveys';
import Loading from '../../../components/Loading';
import { MatomoEvent, SurveyDashboardTabs, UserRoleType } from '../../../constants';
import { getChartForClosedEndedQuestions } from '../../../fusion-charts/ChartHelper';
import useMatomo from '../../../hooks/useMatomo';
import useParams from '../../../hooks/useParams';
import useRoles from '../../../hooks/useRoles';
import { queryType } from '../../../types';
import ConcernCard from './ConcernCard';
import SurveySummary from './SurveySummary';

const { Title, Text } = Typography;
const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const ChartCard = styled(Card)`
  > div {
    display: flex;
    justify-content: center;
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

function SummaryTab({ questions, setActiveTab, setSelectedOpenEndedQuestionId }) {
  const { surveyId } = useParams();
  const { roles } = useRoles();
  const { trackEvent, trackPageView } = useMatomo();
  const queryClient = useQueryClient();

  const openEndedQuestions = questions.data.filter((q) => q.has_themes && q.code_frame_id);
  const closeEndedQuestions = questions.data.filter((q) => q.is_filterable);

  const [aggregatedResponsesChart, setAggregateResponsesChart] = useState(undefined);
  const [selectedCloseEndedQuestionId, setSelectedCloseEndedQuestionId] = useState(
    closeEndedQuestions[0]?.id,
  );

  const { data: topThreeThemes, isFetching: questionsThemesFetching } = useQuery(
    [
      'codeFrames',
      {
        questionIds: openEndedQuestions.map((q) => q.id).join(),
        filters: { only_final: true },
        surveyId,
      },
    ],
    useCodeFrames(),
    {
      enabled: !!openEndedQuestions.length,
      select: (data) =>
        data.data.map((questionTheme) => {
          const themeJson = questionTheme.theme_json.themes
            .map((themes) => themes.data)
            .slice(0, 3);
          return {
            ...questionTheme,
            theme_json: { ...questionTheme.theme_json, themes: themeJson },
          };
        }),
    },
  );

  const { isFetching: aggregateResponsesFetching } = useQuery(
    ['aggregrateResponses', { questionId: selectedCloseEndedQuestionId, surveyId }],
    useAggregateResponses(),
    {
      enabled: !!selectedCloseEndedQuestionId,
      onSuccess: (data) => {
        const questionInfo = closeEndedQuestions.find((q) => q.id === selectedCloseEndedQuestionId);
        setAggregateResponsesChart(getChartForClosedEndedQuestions(data.data, questionInfo.text));
      },
    },
  );

  const { data: summary, isFetching: summaryFetching } = useQuery(
    ['surveySummary', { surveyId }],
    useSurveySummary(),
  );

  const { mutateAsync: updateDisplaySentiment, isLoading: updateDisplaySentimentLoading } =
    useMutation(useUpdateDisplaySentiment());

  const handleSentimentSwitch = async (checked, questionId) => {
    try {
      await updateDisplaySentiment({
        questionId,
        data: JSON.stringify({ display_sentiment: checked }),
      });
      queryClient.setQueryData(['surveyDashboardQuestions', { surveyId }], (prevQuestions) => {
        const updatedQuestions = prevQuestions.data.map((q) =>
          q.id === questionId ? { ...q, display_sentiment: checked } : q,
        );
        return { ...prevQuestions, data: updatedQuestions };
      });
      if (checked) {
        message.success('Sentiment analysis visible for all users');
      } else {
        message.success('Sentiment analysis hidden for all users');
      }
    } catch {
      message.error('Something went wrong');
    }
  };

  useEffect(() => {
    trackPageView('Survey Dashboard');
  }, [trackPageView]);

  const questionCardButtons = (questionId, displaySentiment) => {
    const buttons = [
      <Button
        key="explore-themes"
        type="link"
        onClick={() => {
          trackEvent(MatomoEvent.EXPLORE_THEME_CLICKS);
          setSelectedOpenEndedQuestionId(questionId);
          setActiveTab(SurveyDashboardTabs.THEMES);
        }}
      >
        Explore themes
      </Button>,
    ];
    if (displaySentiment) {
      buttons.push(
        <Button
          key="explore-sentiments"
          type="link"
          onClick={() => {
            trackEvent(MatomoEvent.EXPLORE_SENTIMENT_CLICKS);
            setSelectedOpenEndedQuestionId(questionId);
            setActiveTab(SurveyDashboardTabs.SENTIMENTS);
          }}
        >
          Explore sentiments
        </Button>,
      );
    }
    return buttons;
  };

  if (questionsThemesFetching || summaryFetching) {
    return <Loading />;
  }

  return (
    <>
      {summary?.data.summary &&
        (roles.includes(UserRoleType.ADMINISTRATOR) ||
          roles.includes(UserRoleType.EDITOR) ||
          summary.data.summary_generated) && <SurveySummary summary={summary.data} />}
      {openEndedQuestions.length > 0 && (
        <>
          <Title level={4}>Common themes for each open-ended question</Title>
          <Flex vertical gap={8}>
            {openEndedQuestions.map((question, index) => (
              <StyledCard
                key={question.id}
                title={question.text}
                extra={
                  (roles.includes(UserRoleType.ADMINISTRATOR) ||
                    roles.includes(UserRoleType.EDITOR)) && (
                    <Space>
                      <Text type="secondary">Sentiment analysis</Text>
                      <Switch
                        checked={question.display_sentiment}
                        disabled={updateDisplaySentimentLoading}
                        onChange={(checked) => handleSentimentSwitch(checked, question.id)}
                      />
                    </Space>
                  )
                }
                actions={questionCardButtons(question.id, question.display_sentiment)}
                data-cy="theme-card"
              >
                <Row>
                  {topThreeThemes
                    .find((t) => t.question_id === question.id)
                    .theme_json.themes.map((theme) => (
                      <Col key={theme.id} xs={24} md={8}>
                        <ConcernCard
                          theme={theme}
                          index={index}
                          displaySentiment={question.display_sentiment}
                        />
                      </Col>
                    ))}
                </Row>
              </StyledCard>
            ))}
          </Flex>
        </>
      )}
      {closeEndedQuestions.length > 0 && (
        <>
          <Title level={4}>Responses from multiple choice questions</Title>
          <Flex vertical gap={8}>
            <StyledSelect
              onClick={() => {
                trackEvent(MatomoEvent.SEARCH_BAR_RESPONSE_CLICKS);
              }}
              value={selectedCloseEndedQuestionId}
              loading={aggregateResponsesFetching}
              onChange={setSelectedCloseEndedQuestionId}
            >
              {closeEndedQuestions.map((q) => (
                <Option key={q.id} value={q.id}>
                  {q.text}
                </Option>
              ))}
            </StyledSelect>
            {aggregatedResponsesChart && (
              <ChartCard data-cy="fusion-chart">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <ReactFC {...aggregatedResponsesChart} />
              </ChartCard>
            )}
          </Flex>
        </>
      )}
    </>
  );
}

SummaryTab.propTypes = {
  questions: queryType.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setSelectedOpenEndedQuestionId: PropTypes.func.isRequired,
};

export default SummaryTab;
